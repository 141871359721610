import Banner from "../components/common/Banner";
import { Trans, useTranslation } from "react-i18next";
import courseCatalogBanner from "../images/courseCatalogBanner.webp";
import PageTitle from "../components/common/PageTitle";
import Search from "../components/caregiverTrainings/Search";
import { Helmet } from "react-helmet";
import { CAREGIVER_TRAININGS_URL, ELIGIBILITY_URL, GOOGLE_FORM_URL } from "../constant/path";
import { Link } from "react-router-dom";
import InfoContainerOther from '../components/home/InfoContainerOther';
import { Typography, Alert, Container } from "@mui/material";

export default function CaregiverTrainings() {
  const translationNamespace = ["caregiverTrainingsTranslations"];
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t('metaDesc')
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />dd
        <link rel="canonical" href={`${process.env.REACT_APP_URL}/${CAREGIVER_TRAININGS_URL}`}/>
      </Helmet>
      <div className="main-content-wrapper" data-testid="caregiver-trainings-view">
        <Container maxWidth="xl">
 <Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
            <Typography variant="h5">
          Incentive applications are closed, but unpaid trainings will remain available.</Typography>
        </Alert>
        </Container>
       
        <PageTitle title={t("title")} />
        <Banner
          imageUrl={courseCatalogBanner}
          title={t("banner.title")}
          subTitle={t("banner.subTitle")}
          description={
            <Trans
              components={{br: <br />, Link1: <Link to={`../${ELIGIBILITY_URL}`} />}}
            >
              {t("banner.description")}
            </Trans>
          }
        />
        <Search />
        {/* <InfoContainerOther
          buttonId='trainings_google_btn'
          title='Did you find what you were looking for?'
          description='caregiverCoach.description'
          buttonText='Let Us Know'
          url={`../${GOOGLE_FORM_URL}`} /> */}
      </div>
    </>
  );
}
