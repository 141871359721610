import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { CourseShort } from "../../model/courseShort";
import { Session } from "../../model/session";
import { useTranslation } from "react-i18next";
import generateUrl from "../../hooks/generateUrl";
import { Course } from "../../model/course";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    ":hover": {
        cursor: "pointer",
    },
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function ResultList(props: {
    results: CourseShort[];
    editMode?: boolean;
    handleDeleteCourse?: any;
    handleEditCourse?: any;
}) {
    const translationNamespace = ["caregiverTrainingsTranslations"];
    const { t, i18n } = useTranslation(translationNamespace);
    const { results } = props;
    const navigate = useNavigate();

    const handleSeeMore = (name: string, number: string, sessions: Session[], formats: string[]) => {
        navigate(generateUrl(name, number, sessions, formats, i18n.language));
    };

    const getFormats = (sessions: Session[]) => {
        const formats: string[] = [];
        sessions.forEach((session: Session) => {
            if (session.formats) {
                session.formats.forEach((format) => {
                    if (!formats.includes(format)) {
                        formats.push(format);
                    }
                });
            }
        });
        return formats;
    };

    const getLanguages = (sessions: Session[]) => {
        const languages: string[] = [];
        sessions.forEach((session: Session) => {
            if (session.languages) {
                session.languages.forEach((language) => {
                    if (!languages.includes(language)) {
                        languages.push(language);
                    }
                });
            }
        });
        return languages;
    };

    return (
        <>
            <Grid item xs display={{ xs: "none", lg: "block" }} data-testid="result-list-lg-component">
                <TableContainer component={Paper} className="search-result-list">
                    <Table sx={{ minWidth: 700 }} aria-label="courses">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#426DA9" }}>
                                <StyledTableCell>{t("course")}</StyledTableCell>
                                <StyledTableCell>{t("format")}</StyledTableCell>
                                <StyledTableCell>{t("languages")}</StyledTableCell>
                                <StyledTableCell>{t("duration")}</StyledTableCell>
                                {/* <StyledTableCell>{t("difficulty")}</StyledTableCell> */}
                                <StyledTableCell>{props?.editMode ? t("actions") : t("details")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.map((result, index) => {
                                const format =
                                    result.sessionAvailability == false ? result.formats : getFormats(result.sessions);
                                const languages =
                                    result.sessionAvailability == false
                                        ? result.languages
                                        : getLanguages(result.sessions);
                                const sessions = result.sessionAvailability == false ? {} : result.sessions;
                                return (
                                    <StyledTableRow
                                        key={index}
                                        onClick={
                                            props?.editMode
                                                ? undefined
                                                : () =>
                                                      handleSeeMore(result.name, result.number, result.sessions, format)
                                        }
                                    >
                                        <StyledTableCell component="th" scope="row">
                                            {result.name}
                                        </StyledTableCell>
                                        <StyledTableCell>{format.join(", ")}</StyledTableCell>
                                        <StyledTableCell>{languages.join(", ")}</StyledTableCell>
                                        <StyledTableCell>{result.durationHours}</StyledTableCell>
                                        <StyledTableCell>
                                            {props?.editMode ? (
                                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                    <Button
                                                        aria-label="Edit Course"
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        className="btn-edit-course"
                                                        sx={{ marginRight: "0.5rem" }}
                                                        onClick={() => props?.handleEditCourse(result as any)}
                                                    >
                                                        {t("editCourse")}
                                                    </Button>
                                                    <Button
                                                        aria-label="Delete Course"
                                                        variant="contained"
                                                        color="error"
                                                        size="small"
                                                        className="btn-delete-course"
                                                        onClick={() => props?.handleDeleteCourse(result as any)}
                                                    >
                                                        {t("deleteCourse")}
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <Button
                                                    aria-label="View Course"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    className="btn-view-course"
                                                >
                                                    {t("viewCourse")}
                                                </Button>
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid
                item
                sx={{ paddingLeft: "0" }}
                display={{ sm: "block", lg: "none" }}
                className="m-course-catalog-section"
                data-testid="result-list-sm-component"
            >
                <Grid item sm className="m-course-title">
                    <Typography variant="h5">{t("courses")}</Typography>
                </Grid>
                {results.map((result, index) => {
                    const format = result.sessionAvailability == false ? result.formats : getFormats(result.sessions);
                    const languages =
                        result.sessionAvailability == false ? result.languages : getLanguages(result.sessions);
                    const sessions = result.sessionAvailability == false ? {} : result.sessions;
                    return (
                        <Grid container key={index} border={1} className="m-single-result-info">
                            <Grid item sm={12} md={12}>
                                <Typography
                                    variant="h6"
                                    className="m-search-result-title"
                                    sx={{ marginBottom: "30px", fontSize: "1rem" }}
                                >
                                    {result.name}
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                                    {t("duration")}
                                </Typography>
                                <Typography>{result.durationHours}</Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                                    {t("format")}
                                </Typography>
                                <Typography>{format.join(", ")}</Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ marginTop: "10px" }}>
                                <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                                    {t("languages")}
                                </Typography>
                                <Typography>{languages.join(", ")}</Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ marginTop: "25px", display: "flex", justifyContent: "center" }}>
                                {props?.editMode ? (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Button
                                            aria-label="Edit Course"
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className="btn-edit-course"
                                            sx={{ marginRight: "0.5rem" }}
                                            onClick={() => props?.handleEditCourse(result as any)}
                                        >
                                            {t("editCourse")}
                                        </Button>
                                        <Button
                                            aria-label="Delete Course"
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            className="btn-delete-course"
                                            onClick={() => props?.handleDeleteCourse(result as any)}
                                        >
                                            {t("deleteCourse")}
                                        </Button>
                                    </Box>
                                ) : (
                                    <Button
                                        aria-label="View Course"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() =>
                                            handleSeeMore(result.name, result.number, result.sessions, format)
                                        }
                                        className="btn-view-course"
                                    >
                                        {t("viewCourse")}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
}

export default ResultList;
