import Banner from "../components/common/Banner";
import { Trans, useTranslation } from "react-i18next";
import homeBannerImage from "../images/homeBanner.webp";
import InfoCards from "../components/home/InfoCards";
import SearchBox from "../components/home/SearchBox";
// import CheckIcon from '@mui/icons-material/Check';
// import InfoContainer from "../components/home/InfoContainer";
// import WhatWillYouLearn from "../components/home/WhatWillYouLearn";
// import { CAREGIVER_COACHING_URL, ELIGIBILITY_URL } from "../constant/path";
// import { ELIGIBILITY_URL } from "../constant/path";
import { GET_PAID_URL } from "../constant/path";
import {Helmet} from "react-helmet";
import HomeFaq from "../components/home/HomeFAQ";
import { Link } from "react-router-dom";
import Testimonial from "../components/home/Testimonial";
import { Typography, Alert, Container } from "@mui/material";

export default function Home() {
  const translationNamespace = ["homeTranslations"];
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t('metaDesc')
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={process.env.REACT_APP_URL}/>

      </Helmet>

      <div className="main-content-wrapper" data-testid="home-view">
        <Container maxWidth="xl">
        <Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
        <Typography variant="h5">
          Incentive applications are closed, but unpaid trainings will remain available through 9/30/24.</Typography>
        </Alert><br/>
        </Container>
 
        <Banner
          imageUrl={homeBannerImage}
          title={t("banner.title")}
          subTitle=""
          description={
          <Trans  components={{ Link1: <Link id="home_banner_action1_link" to={GET_PAID_URL} /> }}> 
            {t("banner.description")}
          </Trans>
        }
        />
        
        <InfoCards />
        
        <HomeFaq />
        {/* <WhatWillYouLearn /> */}
        <Testimonial/>
        <SearchBox />
        {/* <InfoContainer
          title={t("caregiverCoach.title")}
          description={t("caregiverCoach.description")}
          buttonText={t("caregiverCoach.buttonText")}
          link={CAREGIVER_COACHING_URL}
          buttonId="home_get_started_btn"
        /> */}
      </div>
    </>
  );
}
