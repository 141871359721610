import { useTranslation } from "react-i18next";
import { SURVEY_URL } from '../constant/path';
import { SURVEY_ES_URL } from "../constant/path";
import { Helmet } from 'react-helmet';
import { Typography, Alert, Container } from "@mui/material";

export default function Survey() {
  const translationNamespace = ['surveyTranslations']
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t('metaDesc')
  const htmlFile = `https://docs.google.com/forms/d/e/1FAIpQLSfyomzw_9NrmKRfjYx_hAm41S5RVQlSq8p6yLCsEEAmIMCKuQ/viewform?embedded=true`
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}/${SURVEY_URL}`} />
      </Helmet>
      <div className="main-content-wrapper">
        <Container maxWidth="xl">
 <Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
          <Typography variant="h5">
          Incentive applications are closed, but unpaid trainings will remain available.</Typography>
        </Alert>
        </Container>
       
        <Typography variant="subtitle1" gutterBottom>
          <center><a href={SURVEY_ES_URL}>En Espanol</a></center>
        </Typography>
        <iframe src={htmlFile} style={{ width: "100%", height: "1660px", border: 'none' }}></iframe>
      </div>
    </>
  );
}