import {
  Grid,
  Container,
  Typography,
  Link,
  List,
  ListItem,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { PORTAL_URL, CDA_URL, HSFORM_URL, EMAIL_CDA } from "../../constant/path";
import Testimonial from "./Testimonial";

export default function HomeFaq() {
  const translationNamespace = ["homeTranslations"];
  const { t } = useTranslation(translationNamespace);

  return (
    <div id="eligibility">
      <Container
        maxWidth="xl"
        sx={{ p: "100px" }}
        className="hm-learn-today-area"
      >
        <Grid container className="home-faq-section">

          <Typography variant="h3">{t("homeFAQ1.whoTitle")}</Typography>

          <Grid item sm={12} md={12} lg={5}>

            <div>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": { display: "list-item" },
                }}
              >
                <ListItem><Typography variant="h6">{t("homeFAQ1.who1")}</Typography></ListItem>
                <ListItem><Typography variant="h6">{t("homeFAQ1.who2")}</Typography></ListItem>
                <ListItem><Typography variant="h6">{t("homeFAQ1.who5")}</Typography></ListItem>
              </List>
            </div>
            <Typography variant="body1" sx={{ paddingBottom: "25px" }}>
              <Trans
                components={{
                  newLine: <br />,
                  italic: <i />,
                  small: <small />,
                  bold: <strong />,
                  UnderlinedText: (
                    <span style={{ textDecoration: "underline", fontStyle: "italic" }} />
                  ),
                }}
              >
                {t("homeFAQ2.detail4")}
              </Trans>
            </Typography>
          </Grid>

          <Grid item sm={12} md={12} lg={7}>
            <Testimonial />
          </Grid>

        </Grid>
      </Container>
      <Container
        maxWidth="xl"
        sx={{ paddingBottom: "50px" }}
        className="hm-learn-today-area"
      >
        <Grid container className="home-faq-section">
          <Grid item sm={12} md={12} lg={12}>
            <div>
              <Typography variant="h3">{t("homeFAQ2.title")}</Typography>
              <Typography variant="body1">


                <Trans
                  components={{
                    newLine: <br />,
                    italic: <i />,
                    small: <small />,
                    bold: <strong />,
                    UnderlinedText: (
                      <span style={{ textDecoration: "underline", fontStyle: "italic" }} />
                    ),
                  }}
                >
                  {t("homeFAQ2.notTitle")}
                </Trans>
              </Typography>
              <br />
              <Typography variant="body1">

                <Trans
                  components={{
                    Link2: (
                      <Link
                        href={CDA_URL}
                        target="_blank"
                        color="inherit"
                      />
                    ),
                  }}
                >
                  {t("homeFAQ2.not1")}
                </Trans>
              </Typography>
              <br />
              <Typography variant="body1">
                <Trans
                  components={{
                    Link2: (
                      <Link
                        href={CDA_URL}
                        target="_blank"
                        color="inherit"
                      />
                    ),
                  }}

                >
                  {t("homeFAQ2.not2")}
                </Trans>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="xl"
        sx={{ paddingBottom: "50px" }}
        className="hm-learn-today-area"
      >
        <Grid container className="home-faq-section">
          <Grid item sm={12} md={12} lg={12}>
            <div>
              <Typography variant="h3">{t("homeFAQ3.title")}</Typography>
              <Typography variant="body1">
                <Trans
                  components={{
                    newLine: <br />,
                    italic: <i />,
                    small: <small />,
                    bold: <strong />,
                    UnderlinedText: (
                      <span style={{ textDecoration: "underline", fontStyle: "italic" }} />
                    ),
                  }}
                >
                  {t("homeFAQ3.detail")}
                </Trans>
              </Typography>
              <div className="hm-topic-list">
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": { display: "list-item" },
                  }}
                >
                  <ListItem>
                    <Trans
                      components={{
                        Link1: (
                          <Link
                            href={EMAIL_CDA}
                            target="_blank"
                            color="inherit"
                          />
                        ),
                      }}
                    >
                      {t("homeFAQ3.email")}
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans
                      components={{
                        Link2: (
                          <Link
                            href={CDA_URL}
                            target="_blank"
                            color="inherit"
                          />
                        ),
                      }}
                    >
                      {t("homeFAQ3.website")}
                    </Trans>
                  </ListItem>
                </List>
              </div>

            </div>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="xl"
        sx={{ paddingBottom: "75px" }}
        className="hm-learn-today-area"
      >
        <Grid container className="home-faq-section">
          <Grid item sm={12} md={12} lg={12}>

            <Typography variant="h3">{t("homeFAQ4.title")}</Typography>
            <Typography variant="body1">
              <Trans
                components={{
                  newLine: <br />,
                  italic: <i />,
                  small: <small />,
                  bold: <strong />,
                  UnderlinedText: (
                    <span style={{ textDecoration: "underline", fontStyle: "italic" }} />
                  ),
                }}
              >
                {t("homeFAQ4.detail")}
              </Trans>
            </Typography>
            <br />
            <Typography variant="h6" sx={{ color: "#8C4799" }}>
              <Trans
                components={{
                  Link4: (
                    <Link
                      href={PORTAL_URL}
                      target="_blank"
                      color="inherit"
                    />
                  ),
                  bold: <strong />,
                }}
              >
                {t("homeFAQ4.detail1")}
              </Trans>
            </Typography>

            <br />
            <Typography variant="body1">
              <Trans
                components={{
                  Link3: (
                    <Link
                      href={HSFORM_URL}
                      target="_blank"
                      color="inherit"
                    />
                  ),
                }}
              >
                {t("homeFAQ4.detail2")}
              </Trans>
            </Typography>
            <br />
            <Typography variant="body1">
              <Trans
                components={{
                  newLine: <br />,
                  italic: <i />,
                  small: <small />,
                  bold: <strong />,
                  UnderlinedText: (
                    <span style={{ textDecoration: "underline", fontStyle: "italic" }} />
                  ),
                }}
              >
                {t("homeFAQ4.detail3")}
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
