import { useLocation, useNavigate, useParams } from "react-router";
import { Fragment, useEffect } from "react";
import { Grid, Container, Typography, Button } from "@mui/material";
import coursesAPI from "../api/course";
import { getCourse, setCourse } from "../store/reducer/course";
import { useDispatch, useSelector } from "react-redux";
import { getApiStatus, setCourseDetailApi } from "../store/reducer/apiStatus";
import SomethingWentWrong from "../components/common/SomethingWentWrong";
import PageTitle from "../components/common/PageTitle";
import Loader from "../components/common/Loader";
import { useTranslation } from "react-i18next";
import { COURSE_CATALOG_URL_WITH_SCROLL, INCENTIVE_CLAIM_URL } from "../constant/path";
import { getVisibility, setIsLoaderVisible } from "../store/reducer/visibility";
import incentiveTraining from "../images/incentiveTraining.svg";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CirclePatternTwo from "../components/common/circlePatternTwo/circlePatternTwo";
import Sessions from "../components/courseDetail/sessions";
import { CourseParams } from "../model/courseParams";
import { Course as ICourse } from "../model/course";
import { Helmet } from "react-helmet";
import CourseTopics from "../components/courseDetail/CourseTopics";
import FAQ from "../components/courseDetail/FAQ";

export default function Course({ preview }: any) {
    const translationNamespace = ["courseDetailTranslations"];
    const { t } = useTranslation(translationNamespace);

    const location = useLocation();

    const { number } = useParams<CourseParams>();
    const navigate = useNavigate();

    const courseResponse = useSelector(getCourse);
    const course = courseResponse.course;
    const apiStatus = useSelector(getApiStatus);
    const visibility = useSelector(getVisibility);
    const dispatch = useDispatch();
    const incentiveTypeNone = t("none");

    const handleBack = () => {
        navigate(`../${COURSE_CATALOG_URL_WITH_SCROLL}`, {
            state: { keepPrevState: true },
        });
    };

    useEffect(() => {
        fetchCourse(number!);
    }, []);

    const fetchCourse = async (number: string) => {
        dispatch(setIsLoaderVisible(true));
        let response;
        if (preview) {
            response = await coursesAPI.getPreviewCourse(number);
        } else {
            response = await coursesAPI.getCourse(number);
        }
        if (response.ok) {
            const { data } = response;
            if (data) {
                sortCourseSessions(data)
                    .then((sortedData) => {
                        dispatch(setCourse(sortedData));
                        dispatch(setCourseDetailApi(true));
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        } else {
            dispatch(setCourseDetailApi(false));
        }
        dispatch(setIsLoaderVisible(false));
    };

    const sortCourseSessions = (data: ICourse): Promise<ICourse> => {
        return new Promise((resolve) => {
            const courseSessions = [...data.sessions];
            const sortedData = { ...data };
            try {
                courseSessions.sort((a, b) => {
                    let minDateA;
                    let minDateB;
                    // This status array should update for each new language.
                    // Static values added to address to language file fetching delay on the existing framework.
                    const statusOrder = [
                        "Open",
                        "Abierto",
                        "Բաց",
                        "열려 있는",
                        "Открыть",
                        "Mở",
                        "打开",
                        "Coming Soon",
                        "Muy pronto",
                        "Շուտով",
                        "곧 출시",
                        "Вскоре",
                        "Sắp ra mắt",
                        "即将推出",
                        "Closed",
                        "Cerrado",
                        "Փակված է",
                        "닫은",
                        "Закрыто",
                        "đóng cửa",
                        "关闭",
                    ];

                    const registrationStatusA = statusOrder.indexOf(a.registrationStatus);
                    const registrationStatusB = statusOrder.indexOf(b.registrationStatus);

                    const dateListA = a.schedules.map((d) => new Date(d.date));
                    const dateListB = b.schedules.map((d) => new Date(d.date));

                    if (dateListA && dateListA.length > 0) {
                        minDateA = dateListA.reduce((a, b) => {
                            return a < b ? a : b;
                        });
                    }

                    if (dateListB && dateListB.length > 0) {
                        minDateB = dateListB.reduce((a, b) => {
                            return a < b ? a : b;
                        });
                    }

                    if (registrationStatusA < registrationStatusB) return -1;
                    if (registrationStatusA > registrationStatusB) return 1;
                    if (minDateA && minDateB) {
                        if (minDateA < minDateB) return -1;
                        if (minDateA > minDateB) return 1;
                    }
                    return 0;
                });
                sortedData.sessions = courseSessions;
                resolve(sortedData);
            } catch (e) {
                resolve(data);
            }
        });
    };

    const incentiveDetails = t("incentiveDetails");
    const metaDesc: string = t("metaDesc");

    return (
        <>
            <Helmet>
                <title>{t("browserTitle")}</title>
                <meta name="description" content={metaDesc} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}${location.pathname}`} />
            </Helmet>
            {visibility.isLoaderVisible ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="main-content-wrapper">
                        <PageTitle title={t("course")} />
                        <div>
                            <Container maxWidth="xl">
                                <Button
                                    className="btn-back-to-result"
                                    onClick={() => {
                                        handleBack();
                                    }}
                                    variant="text"
                                    sx={{
                                        color: "#000",
                                        fontSize: "18px",
                                        fontWeight: 400,
                                        textDecoration: "underline",
                                        textTransform: "initial",
                                    }}
                                >
                                    <ArrowLeftIcon sx={{ color: "#577CAE", fontSize: "32px" }} />
                                    {t("backToResults")}
                                </Button>
                            </Container>
                            {apiStatus.courseDetailApi == true ? (
                                <div>
                                    <Container maxWidth="xl">
                                        <Grid container spacing={3} sx={{ p: "12px 24px" }}>
                                            <Typography
                                                variant="h2"
                                                sx={{ color: "#8C4799", fontSize: "40px" }}
                                                className="course-details-title"
                                            >
                                                {course.name}
                                            </Typography>
                                        </Grid>
                                    </Container>
                                    <Container maxWidth="xl">
                                        <Grid container>
                                            <Grid
                                                item
                                                sm={12}
                                                md={6}
                                                sx={{ marginTop: "30px", paddingRight: "30px" }}
                                                className="pl-0 course-info-block"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                >
                                                    {t("courseInfo")}
                                                </Typography>
                                               
                                                <div
                                                    style={{ fontSize: "18px", fontWeight: "400" }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: course.description,
                                                    }}
                                                    className="course-info-text"
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontSize: "18px",
                                                        paddingBottom: "10px",
                                                        marginTop: "30px",
                                                    }}
                                                    className="mb-20"
                                                >
                                                    {t("courseNumber")}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontSize: "18px",
                                                        paddingBottom: "10px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {course.number}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                >
                                                        {t("whoCan")}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontSize: "18px",
                                                        paddingBottom: "10px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                        {course.whoTheClassisDesignedFor}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                sx={{
                                                    marginTop: "30px",
                                                    display: "flex",
                                                    flexFlow: "column",
                                                }}
                                                className="pl-0 mt-0"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                    className="mb-20"
                                                >
                                                    {t("topics")}
                                                </Typography>
                                                {course.topics.length == 0 ? (
                                                    <Typography>{t("notApplicable")}</Typography>
                                                ) : (
                                                    <CourseTopics topics={course.topics} />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {course.prerequisticsDesc && (
                                                <Grid
                                                    item
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    sx={{ marginTop: "30px", paddingRight: "30px" }}
                                                    className="pl-0 take-course-block"
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                        className="mb-20"
                                                    >
                                                            {t("prerequisticsDesc")}
                                                    </Typography>
                                                    <div
                                                        style={{ fontSize: "18px", fontWeight: "400" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: course.prerequisticsDesc,
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                            {course.trainingPartnerName && (
                                                <Grid item sm={12} md={6} lg={6} className="pl-0">
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                        className="mb-20"
                                                    >
                                                        {t("trainingPartner")}
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                    >
                                                        {t("thisCourseIsOfferedBy")}
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: "18px", paddingBottom: "10px" }}
                                                    >
                                                        {course.trainingPartnerName}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Container>
                                    {course.incentiveEligibility && (
                                        <div className="session-eligibility-block">
                                            <Container maxWidth="xl">
                                                <Grid container>
                                                    <Grid item sm={12} className="m-icon-incentive-claim">
                                                        <img
                                                            src={incentiveTraining}
                                                            width="115"
                                                            alt={incentiveDetails}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={9}>
                                                        <Typography
                                                            variant="h3"
                                                            sx={{
                                                                fontSize: "48px",
                                                                paddingBottom: "10px",

                                                                textTransform: "initial",
                                                            }}
                                                        >
                                                            {incentiveDetails}
                                                        </Typography>
                                                        {course.incentiveTypeName && (
                                                            <Typography variant="h6">
                                                                {course.incentiveTypeName == incentiveTypeNone
                                                                    ? t("noIncentiveTrack")
                                                                    : `${t("thisCourseIsPartOfThe")} ${
                                                                          course.incentiveTypeName
                                                                      }`}
                                                            </Typography>
                                                        )}
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: course.incentiveEligibility,
                                                            }}
                                                        />

                                                        {course.incentiveTypeName &&
                                                            course.incentiveTypeName != incentiveTypeNone && (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    md={12}
                                                                    className="submit-claim-block"
                                                                >
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href={INCENTIVE_CLAIM_URL}
                                                                        className="btn-submit-claim"
                                                                    >
                                                                        {t("submitIncentiveClaim")}
                                                                    </a>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item sm={12} md={3} className="icon-claim-incentive">
                                                        <img
                                                            src={incentiveTraining}
                                                            width="115"
                                                            alt={incentiveDetails}
                                                        />
                                                    </Grid>
                                                    <span className="session-circle-pattern">
                                                        <CirclePatternTwo />
                                                    </span>
                                                </Grid>
                                            </Container>
                                        </div>
                                    )}
                                    <Container maxWidth="xl" sx={{ marginBottom: "32px" }}>
                                        <Sessions course={course} />
                                    </Container>
                                </div>
                            ) : (
                                <SomethingWentWrong />
                            )}
                            <Container maxWidth="xl">
                                <FAQ />
                            </Container>
                        </div>
                    </div>
                </Fragment>
            )}
        </>
    );
}
