import { Grid, Container, Link, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import {
  CDA_URL,
  TOS_URL,
  PRIVACY_URL,
  PCG_URL,
  TRAINING_PARTNERS_URL
  // ABOUT_CAL_GROWS,
  // INNOVATION_FUND_URL,
  // IN_THE_NEWS,
  // OUTREACH_PARTNERS_TOOLKIT_URL,
  // MEDIA_FACT_SHEET_URL,
} from '../../constant/path';
import logo from '../../images/calgrows-logo-white.png';
import CDALogo from '../../images/cda-logo-hor-white.png';
import { useTranslation, Trans } from 'react-i18next';
// import { PhoneRounded, MailRounded } from '@mui/icons-material';
import './footer.scss';

export default function Footer() {
  const translationNamespace = ['footerTranslations'];
  const { t } = useTranslation(translationNamespace);

  const copyRightLine1 = t('copyRightLine1');
  const copyRightLine2 = t('copyRightLine2');
  const terms = t('terms');
  const privacy = t('privacy');

  return (
    <footer className="footer" data-testid="footer-container">
      <Container maxWidth="xl">
        <Grid
          container
          item
          sm={12}
          justifyContent="center"
          className="footer__top-container"
        >
          <img src={logo} width="204" height="37" alt={`CalGrows ${t('home')}`} />
        </Grid>

        <Grid container className="footer__main-container">
          <Grid
            item
            sm={12}
            md={3}
            lg={3}
            className="footer__links-col"
            justifyContent="center"
            container
          >
            <ul className="footer__ul">
              <li className="footer__li-title">{t('aboutCalGrows')}</li>
              {/* <li className="footer__li">
                <RouterLink
                  color="inherit"
                  id='footer_about_calgrows_link'
                  to={`${ABOUT_CAL_GROWS}`}
                  className="links"
                >
                  {t('footerLink2')}
                </RouterLink>
              </li> */}
              {/* <li className="footer__li">
                <Link
                  id="footer_outreach_partner_link"
                  color="inherit"
                  href={OUTREACH_PARTNERS_TOOLKIT_URL}
                  target='_blank'
                  className="links">
                  {t('footerLink1')}
                </Link>
              </li> */}
              {/* <li className="footer__li">
                <Link
                  id="footer_outreach_partner_link"
                  color="inherit"
                  href={MEDIA_FACT_SHEET_URL}
                  target='_blank'
                  className="links">
                  {t('footerLink3')}
                </Link>
              </li> */}
              <li className="footer__li">
                <Link
                  id="footer_training_partners_link"
                  color="inherit"
                  href={TRAINING_PARTNERS_URL}
                  target='_blank'
                  className="links">
                  {t('footerLink5')}
                </Link>
              </li>
              <li>
                <Button
                variant="contained"
                className="footer-btn"
                onClick={() => { window.open('https://forms.gle/TuFqhFCpP3rbH8Nn9', '_blank'); }}
              >
                Subscribe to Emails
              </Button>
              </li>
             
              {/* <li className="footer__li">
                <Link
                  color="inherit"
                  id="footer_in_the_news_link"
                  href={IN_THE_NEWS}
                  className="links"
                  target="_blank"
                >
                  {t('inTheNews')}
                </Link>
              </li> */}

              {/* <li className="footer__li">
                <Link color="inherit" href="#" className="links">
                  {t('footerLink3')}
                </Link>
              </li>  URL available in next sprint  */}
              {/* <li className="footer__li">
                <Link
                  color="inherit"
                  id="footer_innovation_fund_link"
                  href={`/${INNOVATION_FUND_URL}`}
                  className="links"
                  target="_blank"
                >
                  {t('footerLink4')}
                </Link>
              </li> */}
            </ul>

          </Grid>

          <Grid
            item
            sm={12}
            md={3}
            lg={2}
            className="footer__links-col"
            justifyContent="center"
            container
          >
            <ul className="footer__ul">
              <li className="footer__li-title">{t('haveAQuestion')}</li>
              {/* <li className="footer__li">
                <PhoneRounded
                  aria-label="phone"
                  sx={{ fontSize: 16, verticalAlign: 'middle' }}
                />
                If you have questions about program eligibility or course selection, please call&nbsp;
                <Link
                  target="_blank"
                  color="inherit"
                  href="tel:855-916-4091"
                  id='footer_contact_number'
                  className="links call-link"
                  underline='none'
                >
                  888-991-7234.  
                </Link>
              </li> */}
              <li className="footer__li">
                {/* <MailRounded
                  aria-label="mail"
                  sx={{ fontSize: 16, verticalAlign: 'middle' }}
                /> */}
                If you have questions regarding the stipend payment process or the status of your application, please call 855-916-4091 or email us at&nbsp; 
                <Link
                  target="_blank"
                  id="footer_message_calgrows_link"
                  color="inherit"
                  href="mailto:help@calgrows.org"
                  className="links mail-link"
                >
                  {t('pleaseCompleteThisForm')}
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            container
            sm={12}
            md={6}
            lg={7}
            className="footer__copyright-col"
          >
            <Grid
              item
              sm={12}
              md={12}
              lg={8}
              className="footer__copyright-content"
            >
              <ul className="footer__ul">
                <li className="footer__li">
                  <Trans
                    components={{
                      Link: (
                        <Link
                          href={CDA_URL}
                          target="_blank"
                          id="footer_cda_link"
                          rel="noopener noreferrer"
                          color="inherit"
                        />
                      ),
                    }}
                  >
                    {copyRightLine1}
                  </Trans>
                </li>
                <li className="footer__li">
                  <Trans
                    components={{
                      Link: (
                        <Link
                          href={PCG_URL}
                          id="footer_cell_ed_link"
                          target="_blank"
                          rel="noopener noreferrer"
                          color="inherit"
                        />
                      ),
                    }}
                  >
                    {copyRightLine2}
                  </Trans>
                </li>
                <li className="footer__li">{t('copyRightLine3')}</li>
                <li className="footer__li">
                  <Link
                    target="_blank"
                    id="footer_terms_of_service_link"
                    rel="noopener noreferrer"
                    href={TOS_URL}
                    color="inherit"
                  >
                    {terms}
                  </Link>
                  &nbsp; | &nbsp;
                  <Link
                    target="_blank"
                    id="footer_privacy_policy_link"
                    rel="noopener noreferrer"
                    href={PRIVACY_URL}
                    color="inherit"
                  >
                    {privacy}
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              container
              item
              sm={12}
              md={12}
              lg={4}
              alignItems="center"
              className="footer__logo-col"
              justifyContent={{ xs: 'center', sm: 'center' }}
            >
              <img
                className="footer__cda-logo"
                src={CDALogo}
                width="216"
                height="54"
                alt={`${t('CDA')}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
