import { Link } from "react-router-dom";
import image from "../../images/testimonialMiaFrayseth.webp";

export default function Testimonial() {

  return (
    <div className="learn-today-bg">
      <Link to="https://www.youtube.com/watch?v=1BT3h5vsb0I" target="_blank" rel="noopener noreferrer">
        <img src={image} width='520px' alt='testimonial' />
      </Link>
    </div>
  );
}
