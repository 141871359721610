import * as React from 'react';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  InputBase,
  IconButton,
  Button,
  Container,
  FormLabel,
  Typography,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getSearchFilters, setTopics } from "../../store/reducer/searchFilters";
import coursesAPI from "../../api/course";
import {
  getApiStatus,
  setFiltersApi,
} from "../../store/reducer/apiStatus";
import { TopicsFilter } from "../../model/topicsFIlter";
import { useNavigate } from "react-router";
import { COURSE_CATALOG_URL_WITH_SCROLL } from "../../constant/path";
import DropdownMultiSelect from "../common/DropdownMultiSelect";
import SomethingWentWrong from "../common/SomethingWentWrong";
import { useTranslation } from "react-i18next";
import { resetSearchParameters, setSearchText, setTopic } from "../../store/reducer/searchParameters";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SearchBox() {
  const [searchTextHome, setSearchTextHome] = useState<string>("")
  const [topicsHome, setTopicsHome] = useState<string[]>([])

  const translationNamespace = ["homeTranslations"];
  const { t } = useTranslation(translationNamespace);

  const searchFilters = useSelector(getSearchFilters);
  const apiStatus = useSelector(getApiStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleKeywordChanges = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTextHome(e.target.value);
  };

  const fetchFilters = async () => {
    const filterResponse = await coursesAPI.getFilters();
    if (filterResponse.ok) {
      const result = filterResponse.data;
      if (result) {
        dispatch(setTopics(result.topics));
      }
    } else {
      dispatch(setFiltersApi(false));
    }
  };

  const formatTopics = (topics: TopicsFilter[]) =>
    topics.map((obj) => obj.topic);

  useEffect(() => {
    fetchFilters();
  }, []);

  const loadCourses = (event: SyntheticEvent) => {
    event.preventDefault();
    dispatch(resetSearchParameters())
    dispatch(setSearchText(searchTextHome))
    dispatch(setTopic(topicsHome))
    navigate(COURSE_CATALOG_URL_WITH_SCROLL, { state: { searchCourses: true, keepPrevState: true } })
  };


  const listAllCourses = () => {
    navigate(COURSE_CATALOG_URL_WITH_SCROLL, { state: { fetchAllCourses: true } })
  };

  const [open, setOpen] = React.useState(false);
  const [fullWidth,] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const searchTranslation = t("search");

  return (
    <>
      {apiStatus.filtersApi == true ? (
        <div className="position-relative find-course-section" data-testid="search-box-component">
          <Container maxWidth="xl" className="hm-search-course">
            <Grid container spacing={3} className="inner-search-box">
              <Grid item xs={12} className='pt-0'>
                <Typography variant="h3">{t("findACourse")}</Typography>
              </Grid>
              <Grid item lg={4} className="grid-inner">
                <Paper
                  component="form"
                  onSubmit={loadCourses}
                  sx={{
                    marginTop: "10px",
                    p: "8px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    id="search"
                    name="search"
                    onChangeCapture={handleKeywordChanges}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={searchTranslation}
                    inputProps={{ "aria-label": "search" }}
                    autoComplete="off"
                    required
                    value={searchTextHome}
                  />
                </Paper>
              </Grid>
              <Grid item lg={4} className="position-relative grid-inner">
                <FormLabel className="top-text">{t("filterBy")}:</FormLabel>
                <Box sx={{ marginTop: "10px" }}>
                  <DropdownMultiSelect
                    onChange={(value: string[]) => setTopicsHome(value)}
                    opts={
                      searchFilters.topics
                        ? formatTopics(searchFilters.topics)
                        : []
                    }
                    selectedValues={
                      topicsHome ? topicsHome : []
                    }
                    name="topics"
                    title={t("topic")}
                  />
                </Box>
              </Grid>
              <Grid item lg={3} className='d-flex align-items-center grid-inner'>
                <div className="position-relative d-flex align-items-center search-btn-block">
                  <Button
                    // onClick={loadCourses}
                    onClick={handleClickOpen}
                    variant="contained"
                    color="primary"
                    sx={{ width: "200px" }}
                    data-testid="search-translation-btn"
                    id="home_find_course_search_btn"

                  >
                    {searchTranslation}
                  </Button>
                  <Button
                    // onClick={listAllCourses}
                    onClick={handleClickOpen}
                    className="text-all-course"
                    data-testid="view-all-courses-btn"
                    id="home_find_course_view_courses_btn"
                  >
                    {t("viewAllCourses")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <SomethingWentWrong />
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Course Waiver"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>The following trainings are not administered by CDA or its third-party administrator, and as such, neither CDA nor its third-party administrator are responsible for the content of these trainings or the representations therein. CDA is not able to provide support or technical assistance regarding the trainings’ content.</strong><br /><br />By signing this waiver, you agree to release, waive, relinquish, and discharge CDA and its third-party administrator and their respective employees, officers, directors, successors, and agents, from any and all claims, demands, liabilities, rights, injuries, damages, expenses, and causes of action of whatever kind or nature regarding the content of the trainings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={loadCourses}
            // onClick={handleClose} 
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
