import Banner from '../components/common/Banner';
import { Trans, useTranslation } from 'react-i18next';
import trainingPartnersBanner from '../images/trainingPartnersBanner.jpg';
import TrainingPrograms from '../components/aboutCalGrows/TrainingPrograms';
import PageTitle from '../components/common/PageTitle';
import { Helmet } from 'react-helmet';
import AboutCalGrowsInfo from '../components/aboutCalGrows/AboutCalGrowsInfo';
import AboutMainComponents from '../components/aboutCalGrows/AboutMainComponents';
import TargetWorkforce from '../components/aboutCalGrows/TargetWorkforce';
import { ABOUT_CAL_GROWS } from '../constant/path';
import { Typography, Alert, Container } from "@mui/material";

export default function AboutCalGrows() {
  const translationNamespace = ['aboutCalGrowsTranslations'];
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t('metaDesc')
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}/${ABOUT_CAL_GROWS}`}/>
      </Helmet>
      <div className="main-content-wrapper" data-testid="about-calGrows-view">
        <Container maxWidth="xl">
 <Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
            <Typography variant="h5">
          Incentive applications are closed, but unpaid trainings will remain available through 9/30/24.</Typography>
        </Alert>
        </Container>
       
        <PageTitle title={t('title')} />
        <Banner
          imageUrl={trainingPartnersBanner}
          title={t('banner.title')}
          subTitle={t('banner.subtitle')}
          description={<Trans>{t("banner.description")}</Trans>}
        />
        <AboutCalGrowsInfo />
        <AboutMainComponents />
        <TargetWorkforce />
        {/* <a href="javascript:void(window.open('https://form.jotform.com/240866561054054','blank','scrollbars=yes,toolbar=no,width=700,height=500'))">Survey</a> */}
        <TrainingPrograms />
      </div>
    </>
  );
}
